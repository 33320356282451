var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"custom-content-container pt-0",attrs:{"cols":"12","md":"9","sm":"9"}},[_c('v-row',[_c('v-col',{attrs:{"md":"8","offset-md":"2"}},[(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:_vm._e(),[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"custom-google-autocomplete"},[_c('v-text-field',{attrs:{"id":"address-map","append-icon":"search","dense":"","filled":"","label":"Search Address","solo":"","flat":"","color":"cyan","loading":_vm.autocompleteLoading},on:{"keyup":_vm.getGoogleAddress,"blur":function($event){_vm.isFocused = false},"focus":function($event){_vm.isFocused = true}},model:{value:(_vm.searchAddress),callback:function ($$v) {_vm.searchAddress=$$v},expression:"searchAddress"}}),((hover || _vm.isFocused) && _vm.autocompleteList.length)?_c('v-list',{staticClass:"custom-google-autocomplete-list",attrs:{"two-line":"","elevation":"4"}},_vm._l((_vm.autocompleteList),function(address,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.selectGoogleAddress(index)}}},[_c('v-list-item-icon',{staticClass:"m-0 mr-3 my-auto"},[_c('v-icon',{attrs:{"color":"cyan"}},[_vm._v(" mdi-map-marker ")])],1),(
                        _vm.lodash.isEmpty(address.structured_formatting) ===
                        false
                      )?_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',{staticClass:"font-size-14"},[_vm._v(_vm._s(address.structured_formatting.main_text))]),_c('v-list-item-subtitle',{staticClass:"font-size-12"},[_vm._v(_vm._s(address.structured_formatting.secondary_text))])],1):_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',{staticClass:"font-size-14"},[_vm._v(_vm._s(address.description))])],1)],1)}),1):_vm._e()],1)]}}])})],(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:_c('v-text-field',{attrs:{"rules":[
              _vm.validateRules.required(_vm.address.street_1, 'Street 1'),
              _vm.validateRules.minLength(_vm.address.street_1, 'Street 1', 1),
              _vm.validateRules.maxLength(_vm.address.street_1, 'Street 1', 255) ],"dense":"","filled":"","label":"Street 1","solo":"","flat":""},model:{value:(_vm.address.street_1),callback:function ($$v) {_vm.$set(_vm.address, "street_1", $$v)},expression:"address.street_1"}}),(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:_c('v-text-field',{attrs:{"rules":[
              _vm.validateRules.minLength(_vm.address.street_2, 'Street 2', 1),
              _vm.validateRules.maxLength(_vm.address.street_2, 'Street 2', 255) ],"dense":"","filled":"","label":"Street 2","solo":"","flat":""},model:{value:(_vm.address.street_2),callback:function ($$v) {_vm.$set(_vm.address, "street_2", $$v)},expression:"address.street_2"}}),(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:_c('v-text-field',{attrs:{"rules":[
              _vm.validateRules.minLength(_vm.address.unit_no, 'Unit no.', 1),
              _vm.validateRules.maxLength(_vm.address.unit_no, 'Unit no.', 255) ],"dense":"","filled":"","label":"Unit no.","solo":"","flat":""},model:{value:(_vm.address.unit_no),callback:function ($$v) {_vm.$set(_vm.address, "unit_no", $$v)},expression:"address.unit_no"}}),(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('############'),expression:"'############'"}],attrs:{"rules":[
              _vm.validateRules.required(_vm.address.postal_code, 'Postal Code'),
              _vm.validateRules.minLength(_vm.address.postal_code, 'Postal Code', 1),
              _vm.validateRules.maxLength(
                _vm.address.postal_code,
                'Postal Code',
                255
              ) ],"dense":"","filled":"","label":"Postal Code","solo":"","flat":""},model:{value:(_vm.address.postal_code),callback:function ($$v) {_vm.$set(_vm.address, "postal_code", $$v)},expression:"address.postal_code"}})],2)],1)],1),_c('v-col',{staticClass:"custom-sidebar-container grey lighten-3",attrs:{"cols":"12","md":"3","sm":"3"}},[_vm._l((_vm.fieldDescriptions),function(fieldInfo,indx){return [(fieldInfo.field == _vm.currentActiveField)?_c('div',{key:indx,staticClass:"help-sidebar"},[_c('div',{staticClass:"help-sidebar-header"},[(fieldInfo.title)?_c('h3',{staticClass:"section-header-title"},[_vm._v(" "+_vm._s(fieldInfo.title)+" ")]):_vm._e()]),(fieldInfo.description)?_c('p',{staticClass:"help-sidebar-description"},[_vm._v(" "+_vm._s(fieldInfo.description)+" ")]):_vm._e(),(fieldInfo.footer)?_c('h5',{staticClass:"help-sidebar-footer-title"},[_vm._v(" "+_vm._s(fieldInfo.footer)+" ")]):_vm._e(),(fieldInfo.footer_content)?_c('p',{staticClass:"help-sidebar-footer-text"},[_vm._v(" "+_vm._s(fieldInfo.footer_content)+" ")]):_vm._e()]):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }