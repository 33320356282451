<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12" md="9" sm="9">
        <v-row>
          <v-col md="8" offset-md="2">
            <table>
              <tr>
                <td>
                  <template v-if="updateEngineer && pageLoading">
                    <v-skeleton-loader
                      class="custom-skeleton custom-avatar-100px"
                      type="avatar"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <v-progress-circular
                      indeterminate
                      :size="100"
                      :width="10"
                      color="cyan"
                      v-if="primaryLoader"
                    ></v-progress-circular>
                    <v-img
                      v-else
                      :lazy-src="$defaultProfileImage"
                      :src="getProfileImage"
                      max-height="100"
                      max-width="100"
                      height="100px"
                      width="100px"
                      class="custom-grey-border custom-border-radius-50"
                    >
                    </v-img>
                  </template>
                </td>
                <td>
                  <template v-if="updateEngineer && pageLoading">
                    <v-skeleton-loader
                      class="mx-4 custom-skeleton small-skeleton-action-button"
                      type="button"
                    >
                    </v-skeleton-loader>
                  </template>
                  <v-btn
                    v-else
                    class="mx-4 custom-bold-button"
                    outlined
                    small
                    :disabled="primaryLoader"
                    :loading="primaryLoader"
                    v-on:click="selectImage"
                    color="cyan"
                  >
                    <template v-if="personalInfo.profile_image"
                      >Change Image</template
                    >
                    <template v-else>Upload Image</template>
                  </v-btn>
                  <div class="d-none">
                    <v-file-input
                      v-model="rawImage"
                      ref="uploadImage"
                      :rules="imageRules"
                      accept="image/png, image/jpeg, image/bmp"
                    ></v-file-input>
                  </div>
                </td>
              </tr>
            </table>
          </v-col>
          <v-col md="8" offset-md="2">
            <v-row>
              <v-col md="4" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <v-select
                  v-else
                  v-model="personalInfo.title"
                  :items="salutationList"
                  dense
                  filled
                  :rules="[validateRules.required(personalInfo.title, 'Title')]"
                  item-text="text"
                  item-value="value"
                  item-color="cyan"
                  label="Salutation"
                  solo
                  flat
                  color="cyan"
                ></v-select>
              </v-col>
              <v-col md="4" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <v-text-field
                  v-else
                  v-model="personalInfo.first_name"
                  :rules="[
                    validateRules.required(
                      personalInfo.first_name,
                      'First name'
                    ),
                    validateRules.minLength(
                      personalInfo.first_name,
                      'First name',
                      1
                    ),
                    validateRules.maxLength(
                      personalInfo.first_name,
                      'First name',
                      50
                    ),
                  ]"
                  dense
                  filled
                  label="First name"
                  solo
                  flat
                ></v-text-field>
              </v-col>
              <v-col md="4" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <v-text-field
                  v-else
                  v-model="personalInfo.last_name"
                  :rules="[
                    validateRules.minLength(
                      personalInfo.last_name,
                      'Last name',
                      1
                    ),
                    validateRules.maxLength(
                      personalInfo.last_name,
                      'Last name',
                      50
                    ),
                  ]"
                  dense
                  filled
                  label="Last name"
                  solo
                  flat
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <v-text-field
                  v-else
                  v-model="personalInfo.display_name"
                  :rules="[
                    validateRules.required(
                      personalInfo.display_name,
                      'Display name'
                    ),
                    validateRules.minLength(
                      personalInfo.display_name,
                      'Display name',
                      1
                    ),
                    validateRules.maxLength(
                      personalInfo.display_name,
                      'Display name',
                      50
                    ),
                  ]"
                  dense
                  filled
                  label="Display name"
                  solo
                  flat
                ></v-text-field>
              </v-col>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <v-select
                  v-else
                  :items="engineerDesignations"
                  v-model="personalInfo.designation"
                  :rules="[
                    validateRules.required(
                      personalInfo.designation,
                      'Designation'
                    ),
                  ]"
                  dense
                  filled
                  label="Designation"
                  solo
                  flat
                  item-color="cyan"
                  item-text="title"
                  item-value="value"
                ></v-select>
              </v-col>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <v-select
                  v-else
                  :items="engineerSkills"
                  v-model="personalInfo.skills"
                  :rules="[
                    validateRules.required(personalInfo.skills, 'Skills'),
                  ]"
                  dense
                  filled
                  label="Skills"
                  solo
                  flat
                  item-color="cyan"
                  item-text="title"
                  item-value="value"
                  multiple
                ></v-select>
              </v-col>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <v-text-field
                  v-else
                  v-model="personalInfo.leave"
                  :rules="[
                    validateRules.required(personalInfo.leave, 'No. of Leave'),
                    validateRules.minLength(
                      personalInfo.leave,
                      'No. of Leave',
                      1
                    ),
                    validateRules.maxLength(
                      personalInfo.leave,
                      'No. of Leave',
                      2
                    ),
                  ]"
                  dense
                  filled
                  label="No of Leave"
                  solo
                  flat
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <v-text-field
                  v-else
                  v-model="personalInfo.primary_email"
                  :rules="[
                    validateRules.required(
                      personalInfo.primary_email,
                      'Email address'
                    ),
                    validateRules.validEmail(
                      personalInfo.primary_email,
                      'Email address'
                    ),
                    validateRules.minLength(
                      personalInfo.primary_email,
                      'Email address',
                      1
                    ),
                    validateRules.maxLength(
                      personalInfo.primary_email,
                      'Email address',
                      50
                    ),
                  ]"
                  dense
                  filled
                  label="Email address"
                  solo
                  flat
                ></v-text-field>
              </v-col>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <v-text-field
                  v-else
                  v-model="personalInfo.primary_phone"
                  v-mask="currentPhoneMask"
                  :rules="[
                    validateRules.required(
                      personalInfo.primary_phone,
                      'Mobile number'
                    ),
                    validateRules.minLength(
                      personalInfo.primary_phone,
                      'Mobile number',
                      1
                    ),
                    validateRules.maxLength(
                      personalInfo.primary_phone,
                      'Mobile number',
                      20
                    ),
                  ]"
                  dense
                  filled
                  label="Mobile number"
                  solo
                  flat
                ></v-text-field>
              </v-col>
            </v-row>
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-width-100px custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <v-switch
              v-else
              v-model="personalInfo.can_login"
              inset
              :value="1"
              color="cyan"
              hide-details
              label="Allow Login"
              class="mt-0"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="custom-sidebar-container grey lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { EngineerEventBus } from "@/core/lib/engineer/engineer.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import { GET } from "@/core/services/store/request.module";

export default {
  name: "personal-info",
  mixins: [ValidationMixin, CommonMixin, FileManagerMixin],
  props: {
    pageLoading: {
      type: Boolean,
      default: false,
    },
    updateEngineer: {
      type: Boolean,
      default: false,
    },
    engineer: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      engineerSkills: [],
      timeoutLimit: 100,
      timeout: null,
      personalInfo: {
        profile_image: null,
        title: "mr",
        first_name: null,
        last_name: null,
        display_name: null,
        designation: 0,
        primary_email: null,
        primary_phone: null,
        skills: [],
        leave: null,
        can_login: 1,
      },
      rawImage: null,
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  watch: {
    rawImage(param) {
      const _this = this;
      _this.uploadAttachment(param).then((response) => {
        _this.personalInfo.profile_image = _this.lodash.head(response);
      });
    },
    personalInfo: {
      deep: true,
      immediate: true,
      handler(param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          EngineerEventBus.$emit("update:personal-info", param);
        }, _this.timeoutLimit);
      },
    },
    engineer() {
      this.setPersonalInfo();
    },
  },
  methods: {
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "engineer/options",
        })
        .then(({ data }) => {
          _this.engineerDesignations = this.lodash.map(
            data.engineer_designation,
            function (row) {
              return {
                title: row.value,
                value: row.id,
              };
            }
          );
          _this.engineerSkills = this.lodash.map(
            data.ticket_types,
            function (row) {
              return {
                title: row.value,
                value: row.value.toLowerCase(),
              };
            }
          );
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    setPersonalInfo() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.engineer) === false) {
        _this.personalInfo.profile_image = _this.engineer.profile_logo;
        _this.personalInfo.title = _this.engineer.title;
        _this.personalInfo.first_name = _this.engineer.first_name;
        _this.personalInfo.last_name = _this.engineer.last_name;
        _this.personalInfo.display_name = _this.engineer.display_name;
        if (_this.engineer.designation && _this.engineer.designation.id) {
          _this.personalInfo.designation = _this.engineer.designation.id;
        }
        _this.personalInfo.skills = _this.engineer.skills;
        _this.personalInfo.primary_email = _this.engineer.primary_email;
        _this.personalInfo.primary_phone = _this.engineer.primary_phone;
        _this.personalInfo.leave = _this.engineer.leave;
        _this.personalInfo.can_login = +_this.engineer.can_login;
      }
    },
  },
  computed: {
    getProfileImage() {
      return this.lodash.isEmpty(this.personalInfo.profile_image) === false
        ? this.personalInfo.profile_image.file.url
        : null;
    },
  },
  mounted() {
    this.setPersonalInfo();
    this.getOptions();
  },
};
</script>
